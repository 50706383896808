import type { PaletteOptions } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { error, info, neutral, purple, secondary, success, warning } from '../colors';

export const createPalette = (): PaletteOptions => {
  return {
    action: {
      active: neutral[400],
      disabled: alpha(neutral[900], 0.38),
      disabledBackground: alpha(neutral[900], 0.12),
      focus: alpha(neutral[900], 0.16),
      hover: alpha(neutral[900], 0.04),
      selected: alpha(neutral[900], 0.12)
    },
    background: {
      default: '#FCFCFD',
      paper: '#fff'
    },
    divider: '#F2F4F7',
    error,
    info,
    mode: 'light',
    neutral,
    primary: purple,
    secondary,
    success,
    text: {
      primary: neutral[900],
      secondary: neutral[500],
      disabled: alpha(neutral[900], 0.38)
    },
    warning
  };
};
