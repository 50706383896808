import Container from '@mui/material/Container';
import React, { FC } from 'react';

import Page from '../../components/Page';
import Usage from './usage';
import { Route, Routes } from 'react-router-dom';

const View: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <Page title="Usage">
      <Container maxWidth={false}>
        <Routes>
          <Route index element={<Usage />} />
        </Routes>
      </Container>
    </Page>
  );
};

export default View;
