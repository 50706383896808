import React, { FC, ReactNode, createContext, useEffect } from 'react';
import {
  User,
  useCompleteOnboardingMutation,
  useUpdateCurrentUserInfoMutation
} from '../store/pomeriumApi';
import { useIdToken } from 'react-firebase-hooks/auth';
import { firebaseAuth } from '../App';
import { useDispatch } from 'react-redux';
import { setCurrentProperty } from '../slices/current';
import { OpenFeature } from '@openfeature/web-sdk';

type UserState = {
  user?: User;
};

export const UserContext = createContext<UserState>({});

export type UserProviderProps = {
  children?: ReactNode | undefined;
};

export const UserProvider: FC<UserProviderProps> = ({ ...props }) => {
  const [user] = useIdToken(firebaseAuth);
  const dispatch = useDispatch();
  const [updateCurrentUserInfo, { data: pomeriumUser, error }] =
    useUpdateCurrentUserInfoMutation();
  const [_, { isSuccess: completedOnboarding }] = useCompleteOnboardingMutation(
    {
      fixedCacheKey: 'complete-onboarding'
    }
  );
  // the user endpoint is not like other endpoints, it has no "get", so cache clearing doesn't work
  // instead we use the complete-onboarding mutation to signal that we called complete and the user
  // is done with onboarding.
  const pomUser = pomeriumUser && {
    ...pomeriumUser,
    needsOnboarding: pomeriumUser.needsOnboarding && !completedOnboarding
  };

  useEffect(() => {
    if (user?.uid && user?.emailVerified) {
      user.getIdToken(true).then(() => updateCurrentUserInfo());
    }
  }, [user?.uid, user?.emailVerified]);

  useEffect(() => {
    dispatch(setCurrentProperty({ path: 'userId', value: pomUser?.id || '' }));

    if (pomUser?.id) {
      OpenFeature.setContext({
        targetingKey: pomUser.id
      });
    }
  }, [pomUser?.id]);

  return (
    <UserContext.Provider
      {...props}
      value={{ user: pomUser }}
    ></UserContext.Provider>
  );
};

export const UserConsumer = UserContext.Consumer;
