import type { PaletteColor } from '@mui/material';
import type { NeutralColors } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';

const withAlphas = (color: PaletteColor): PaletteColor => {
  return {
    ...color,
    alpha4: alpha(color.main, 0.04),
    alpha8: alpha(color.main, 0.08),
    alpha12: alpha(color.main, 0.12),
    alpha30: alpha(color.main, 0.3),
    alpha50: alpha(color.main, 0.5)
  };
};

export const neutral: NeutralColors = {
  50: '#FAFAFA',
  100: '#F5F5F5',
  200: '#E5E5E5',
  300: '#D4D4D4',
  400: '#A1A1AA',
  500: '#71717A',
  600: '#525252',
  700: '#404040',
  800: '#262626',
  900: '#171717'
};

export const blue = withAlphas({
  light: '#EBEFFF',
  main: '#2970FF',
  dark: '#004EEB',
  contrastText: '#FFFFFF'
});

export const green = withAlphas({
  light: '#6CE9A6',
  main: '#12B76A',
  dark: '#027A48',
  contrastText: '#FFFFFF'
});

export const indigo = withAlphas({
  light: '#EBEEFE',
  main: '#635dff',
  dark: '#4338CA',
  contrastText: '#FFFFFF'
});

export const secondary = withAlphas({
  light: '#fff',
  main: '#EBEEFE',
  dark: neutral[500],
  contrastText: neutral[600]
});

export const purple = withAlphas({
  light: '#B18AF4',
  main: '#7C3AED',
  dark: '#5B14D7',
  contrastText: '#FFFFFF'
});

export const success = withAlphas({
  light: '#3FC79A',
  main: '#10B981',
  dark: '#0B815A',
  contrastText: '#FFFFFF'
});

export const info = withAlphas({
  light: '#CFF9FE',
  main: '#06AED4',
  dark: '#0E7090',
  contrastText: '#FFFFFF'
});

export const warning = withAlphas({
  light: '#D9822B',
  main: '#D9822B',
  dark: '#B54708',
  contrastText: '#FFFFFF'
});

export const error = withAlphas({
  light: '#FEE4E2',
  main: '#F04438',
  dark: '#B42318',
  contrastText: '#FFFFFF'
});
