import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Organization } from '../store/pomeriumApi';
import { organizationId, OrganizationState } from '../types/organizations';
import { AppThunk } from '../store';
import { set } from 'lodash';

const initialState: OrganizationState = {
  byId: {}
};

const slice = createSlice({
  name: 'organizations',
  initialState,
  reducers: {
    setById(
      state: OrganizationState,
      action: PayloadAction<Record<organizationId, Organization>>
    ): void {
      state.byId = action.payload;
    }
  }
});

export const { reducer } = slice;

export const indexOrganizations =
  (organizations: Organization[]): AppThunk =>
  async (dispatch): Promise<void> => {
    const byId = {};
    organizations.forEach((organization) => {
      set(byId, organization.id, organization);
    });
    dispatch(slice.actions.setById(byId));
  };
