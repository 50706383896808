import IconButton from '@mui/material/IconButton';
import { useSnackbar, SnackbarKey } from 'notistack';
import * as React from 'react';
import { XCircle } from 'react-feather';

type SnackbarCloseProps = {
  snackbarKey: SnackbarKey;
};

const SnackbarCloseButton = ({
  snackbarKey
}: SnackbarCloseProps): JSX.Element => {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton onClick={() => closeSnackbar(snackbarKey)} size="large">
      <XCircle color="white" />
    </IconButton>
  );
};

export default SnackbarCloseButton;
