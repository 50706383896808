import { Autocomplete as MuiAutocomplete } from '@mui/material';
import { styled } from '@mui/material/styles';

const Autocomplete = styled(MuiAutocomplete)(({ theme }) => ({
  root: {
    '& .MuiFormControl-root': {
      backgroundColor: theme.palette.background.default
    },
    '& .MuiInputBase-input': {
      padding: theme.spacing(1),
      backgroundColor: theme.palette.background.default
    },
    '& .Mui-error .MuiInputBase-input': {
      backgroundColor: theme.palette.error.main
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: '1px',
      borderColor: theme.palette.divider
    },
    '& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)': {
      transform: 'translate(8px, 10px) scale(1)'
    }
  }
}));

export default Autocomplete as typeof Autocomplete;
