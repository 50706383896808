import React, { FC, useContext, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';
import { useIdToken } from 'react-firebase-hooks/auth';
import { firebaseAuth } from '../App';
import LoadingScreen from '../components/LoadingScreen';
import { useListOrganizationsQuery } from '../store/pomeriumApi';
import objectHash from 'object-hash';

export const NotLoggedInGuard: FC = () => {
  const { user: pomeriumUser } = useContext(UserContext);
  const [user, loading] = useIdToken(firebaseAuth);
  const navigate = useNavigate();
  const { data: organizations } = useListOrganizationsQuery(undefined, {
    skip: !pomeriumUser?.id
  });

  useEffect(() => {
    if (
      pomeriumUser?.id &&
      user?.uid &&
      typeof organizations?.length !== 'undefined'
    ) {
      const redirectUrl = localStorage.getItem('redirectUrl') || '';
      localStorage.setItem('redirectUrl', '');
      const params = new URLSearchParams(redirectUrl.split('?')[1]);
      const oid = params.get('oid');
      const lastOid = localStorage.getItem(
        pomeriumUser?.id + '_organizationId'
      );
      const lastCid = localStorage.getItem(pomeriumUser?.id + '_clusterId');
      if (oid && organizations?.find((o) => o.id === oid)) {
        navigate(redirectUrl, { replace: true });
      } else if (lastOid && organizations?.find((o) => o.id === lastOid)) {
        navigate('app/management/routes?oid=' + lastOid + '&cid=' + lastCid);
      } else {
        navigate('app/management/routes');
      }
    } else if (user?.uid && !user?.emailVerified) {
      navigate('/verify-email', { replace: true });
    }
  }, [
    pomeriumUser?.id,
    user?.uid,
    user?.emailVerified,
    objectHash(organizations || {})
  ]);

  if (loading) {
    return <LoadingScreen />;
  }

  return !user?.uid || !pomeriumUser?.id ? <Outlet /> : <></>;
};
