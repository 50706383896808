import { TimeSeriesMetricId } from '../../store/pomeriumApi';

export function getClusterUsageInterval(
  metric: TimeSeriesMetricId,
  now: Date
): { start: Date; end: Date } {
  const year = now.getUTCFullYear();
  const month = now.getUTCMonth();
  const day = now.getUTCDate();

  if (metric === 'dau') {
    const start = new Date(Date.UTC(year, month - 1, day, 0, 0, 0, 0));
    const end = new Date(Date.UTC(year, month, day + 1, 0, 0, 0, 0));
    return { start, end };
  }
  if (metric === 'mau') {
    const start = new Date(Date.UTC(year - 1, month, 1, 0, 0, 0, 0));
    const end = new Date(Date.UTC(year, month + 1, 1, 0, 0, 0, 0));
    return { start, end };
  }

  throw new Error(`unknown metric: ${metric}`);
}
