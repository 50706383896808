import { createSlice, PayloadAction, Selector } from '@reduxjs/toolkit';
import objectHash from 'object-hash';
import { AppThunk, RootState } from '../store';
import { get, PropertyPath, set, unset } from 'lodash';
import { formError, formUpdater } from '../types/form';
import { Policy } from '../store/pomeriumApi';
import { PolicyState } from '../types/policies';

const initialState: PolicyState = {
  policyHash: '',
  form: {},
  formErrors: {},
  byId: {}
};

const slice = createSlice({
  name: 'policies',
  initialState,
  reducers: {
    setPolicy(state: PolicyState, action: PayloadAction<Policy>): void {
      const hash = objectHash(action.payload);
      state.form = action.payload;
      state.policyHash = hash;
    },
    setPolicyFormProperty(
      state: PolicyState,
      action: PayloadAction<formUpdater>
    ): void {
      //we don't check types here. in the saveToBackend method should make sure the form is a valid Route.
      if (typeof action.payload.value === 'undefined') {
        unset(state.form || {}, action.payload.path);
      } else {
        set(state.form || {}, action.payload.path, action.payload.value);
      }
    },
    setPolicyFormError(
      state: PolicyState,
      action: PayloadAction<formError>
    ): void {
      if (action.payload.error) {
        set(state.formErrors, action.payload.path, action.payload.error);
      } else {
        unset(state.formErrors, action.payload.path);
      }
    },
    clearPolicyHash(state: PolicyState): void {
      state.policyHash = '';
    },
    clearForm(state: PolicyState): void {
      state.form = {};
      state.policyHash = '';
      state.formErrors = {};
    },
    setPolicyById(state: PolicyState, action: PayloadAction<Policy[]>): void {
      const byId = {};
      action.payload.forEach((p) => set(byId, p.id, p));
      state.byId = byId;
    }
  }
});

export const { reducer, actions } = slice;
export const {
  setPolicyById,
  clearPolicyHash,
  clearForm,
  setPolicyFormProperty,
  setPolicyFormError
} = actions;

export const setPolicyForm =
  (policy: Policy): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.setPolicy(policy));
  };

export const getPolicyFormProperty =
  (path: PropertyPath, defaultValue: any): Selector =>
  (store: RootState) => {
    return get(store.policies.form, path, defaultValue) as typeof defaultValue;
  };
