import { routeMode, RouteState } from '../types/routes';
import { createSlice, PayloadAction, Selector } from '@reduxjs/toolkit';
import objectHash from 'object-hash';
import { AppThunk, RootState } from '../store';
import { get, isEmpty, PropertyPath, set, unset } from 'lodash';
import { formError, formUpdater } from '../types/form';
import { Route } from '../store/pomeriumApi';

const initialState: RouteState = {
  routeHash: '',
  form: {},
  formErrors: {},
  routeUrls: [],
  routeMode: 'to'
};

const slice = createSlice({
  name: 'routes',
  initialState,
  reducers: {
    setRoute(state: RouteState, action: PayloadAction<Partial<Route>>): void {
      const hash = objectHash(action.payload);
      state.form = action.payload;
      state.routeHash = hash;
      if ('redirect' in action.payload) {
        state.routeMode = 'redirect';
      } else if ('response' in action.payload) {
        state.routeMode = 'response';
      } else {
        state.routeMode = 'to';
      }
    },
    setRouteFormProperty(
      state: RouteState,
      action: PayloadAction<formUpdater>
    ): void {
      //we don't check types here. in the saveToBackend method should make sure the form is a valid Route.
      if (
        typeof action.payload.value === 'undefined' ||
        action.payload.value === ''
      ) {
        unset(state.form || {}, action.payload.path);
      } else {
        set(state.form || {}, action.payload.path, action.payload.value);
      }
    },
    setIntegerRouteFormProperty(
      state: RouteState,
      action: PayloadAction<formUpdater>
    ): void {
      if (typeof action.payload.value === 'undefined') {
        unset(state.form || {}, action.payload.path);
      } else {
        set(
          state.form || {},
          action.payload.path,
          parseInt(action.payload.value)
        );
      }
    },
    setHealthCheckType(
      state: RouteState,
      action: PayloadAction<formUpdater>
    ): void {
      switch (action.payload.value) {
        case 'tcp':
        case 'grpc':
        case 'http':
          set(state.form || {}, 'healthCheck', { type: action.payload.value });
          break;
        default:
          unset(state.form || {}, 'healthCheck');
      }
    },
    setRouteFormError(
      state: RouteState,
      action: PayloadAction<formError>
    ): void {
      if (action.payload.error) {
        set(state.formErrors, action.payload.path, action.payload.error);
      } else {
        unset(state.formErrors, action.payload.path);
      }
      if (isEmpty(state.formErrors?.healthCheck)) {
        unset(state.formErrors, 'healthCheck');
      }
    },
    clearForm(state: RouteState): void {
      state.form = {};
      state.routeHash = '';
      state.formErrors = {};
    },
    setRouteMode(state: RouteState, action: PayloadAction<routeMode>): void {
      state.routeMode = action.payload;
      switch (action.payload) {
        case 'redirect':
          unset(state.form || {}, 'response');
          unset(state.form || {}, 'to');
          break;
        case 'response':
          unset(state.form || {}, 'redirect');
          unset(state.form || {}, 'to');
          break;
        case 'to':
        default:
          unset(state.form || {}, 'redirect');
          unset(state.form || {}, 'response');
          break;
      }
    }
  }
});

export const { reducer, actions } = slice;
export const {
  setHealthCheckType,
  clearForm,
  setRouteFormProperty,
  setRouteFormError,
  setIntegerRouteFormProperty,
  setRouteMode
} = actions;

export const setFormRoute =
  (route: Partial<Route>): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.setRoute(route));
  };

export const getRouteFormProperty =
  (path: PropertyPath, defaultValue: any): Selector =>
  (store: RootState) => {
    return get(store.routes.form, path, defaultValue) as typeof defaultValue;
  };
