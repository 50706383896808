import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MetricsState } from '../types/metrics';
import { ThirtyDays } from '../views/traffic/traffic';
import { setCurrentProperty } from './current';

const initialState: MetricsState = {
  routeId: '',
  reportPeriod: ThirtyDays
};

const slice = createSlice({
  name: 'metrics',
  initialState,
  reducers: {
    setRouteId(state: MetricsState, action: PayloadAction<string>): void {
      state.routeId = action.payload;
    },

    setReportPeriod(state: MetricsState, action: PayloadAction<number>): void {
      state.reportPeriod = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(setCurrentProperty, (state) => {
      state.routeId = '';
    });
  }
});

export const { reducer, actions } = slice;
export const { setRouteId, setReportPeriod } = actions;
