import slugify from 'slugify';
import { getFirebase } from '../configs/firebase';
import { logEvent } from 'firebase/analytics';
import { User } from '../store/pomeriumApi';

/*
to get user:
const { user } = useContext(UserContext);
passed in because of error when using these within a useEffect which we will need to do
 */
const sendStandardEvent = (
  name: string,
  type: string,
  user: User | undefined,
  additionalAttributes?: { [key: string]: any }
) => {
  const { analytics } = getFirebase();
  const eventId = slugify(name, {
    replacement: '_',
    lower: true
  });
  const properties = {
    name,
    type,
    pomerium_user_id: user?.id || '',
    email: user?.email || '',
    ...additionalAttributes
  };
  if (analytics) {
    // not present if the Firebase Emulator is used
    logEvent(analytics, eventId, properties);
  }
};

export default sendStandardEvent;
