import React, { FC, useContext, useEffect } from 'react';
import {
  Navigate,
  Outlet,
  useLocation,
  useSearchParams
} from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';
import { useIdToken } from 'react-firebase-hooks/auth';
import { firebaseAuth } from '../App';
import LoadingScreen from '../components/LoadingScreen';
import { getFirebase } from '../configs/firebase';
import { setUserId } from 'firebase/analytics';
import { useDispatch, useSelector } from '../store';
import {
  useListClustersQuery,
  useListOrganizationsQuery
} from '../store/pomeriumApi';
import objectHash from 'object-hash';
import { setCurrentProperty } from '../slices/current';
import { get } from 'lodash';

export const AuthGuard: FC = () => {
  const dispatch = useDispatch();
  const { analytics } = getFirebase();
  const { user: pomeriumUser } = useContext(UserContext);
  const [user, loading] = useIdToken(firebaseAuth);
  const { clusterId, organizationId } = useSelector((state) => state.current);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const oid = params.get('oid');
  const cid = params.get('cid');
  const lastOid = localStorage.getItem(pomeriumUser?.id + '_organizationId');
  const lastCid = localStorage.getItem(pomeriumUser?.id + '_clusterId');
  const [_searchParams, setSearchParams] = useSearchParams();
  const { data: clusters } = useListClustersQuery(organizationId, {
    skip: !organizationId || !pomeriumUser?.id
  });
  const { data: organizations } = useListOrganizationsQuery(undefined, {
    skip: !pomeriumUser?.id
  });

  useEffect(() => {
    if (pomeriumUser?.id && organizations) {
      const orgId = oid || lastOid;
      if (
        organizationId !== orgId &&
        orgId &&
        organizations?.find((o) => o.id === orgId)
      ) {
        dispatch(setCurrentProperty({ path: 'organizationId', value: orgId }));
      } else if (
        !organizationId ||
        !organizations?.find((o) => o.id === organizationId)
      ) {
        dispatch(
          setCurrentProperty({
            path: 'organizationId',
            value: get(organizations, '0.id', '')
          })
        );
      }
    }
  }, [oid, lastOid, objectHash(organizations || {}), pomeriumUser?.id]);

  useEffect(() => {
    if (pomeriumUser?.id && clusters) {
      const clustId = cid || lastCid;
      if (
        clusterId !== clustId &&
        clustId &&
        clusters?.find((c) => c.id === clustId)
      ) {
        dispatch(setCurrentProperty({ path: 'clusterId', value: clustId }));
      } else if (!clusterId || !clusters?.find((c) => c.id === clusterId)) {
        dispatch(
          setCurrentProperty({
            path: 'clusterId',
            value: get(clusters, '0.id', '')
          })
        );
      }
    }
  }, [oid, lastOid, objectHash(clusters || {}), pomeriumUser?.id]);

  useEffect(() => {
    if (organizationId) {
      setSearchParams(
        (params) => {
          params.set('oid', organizationId);
          return params;
        },
        { replace: true }
      );
    }
  }, [organizationId]);

  useEffect(() => {
    if (clusterId) {
      setSearchParams(
        (params) => {
          params.set('cid', clusterId);
          return params;
        },
        { replace: true }
      );
    }
  }, [clusterId]);

  if (loading) {
    return <LoadingScreen />;
  }

  if (user && user?.emailVerified && pomeriumUser?.id) {
    if (analytics) {
      setUserId(analytics, user?.uid || '');
    }
    if (
      (organizationId &&
        organizations?.find((o) => o.id === organizationId) &&
        clusterId &&
        clusters?.find((c) => c.id === clusterId)) ||
      organizations?.length === 0 ||
      (typeof clusters !== 'undefined' && clusters?.length <= 1)
    ) {
      return <Outlet />;
    } else {
      return <LoadingScreen />;
    }
  }

  if (!user) {
    if (location.pathname.indexOf('logout') === -1) {
      localStorage.setItem('redirectUrl', location.pathname + location.search);
    }
    return <Navigate to="/login" />;
  }

  return <></>;
};
