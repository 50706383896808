import React, { FC, useEffect } from 'react';
import Page from '../../components/Page';
import { Container } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

export function buildCancelUrl(returnUrl: string | null): string {
  return `${location.origin}/app/billing/cancel${returnUrl ? `?returnUrl=${encodeURIComponent(returnUrl)}` : ''}`;
}

const Cancel: FC = () => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const returnUrl = searchParams.get('returnUrl');
    if (returnUrl) {
      location.href = returnUrl;
    }
  }, [searchParams.get('returnUrl')]);

  return (
    <Page title="Cancel">
      <Container maxWidth={false}>Checkout Cancelled</Container>
    </Page>
  );
};
export default Cancel;
