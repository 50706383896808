import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import { isFinite, omit } from 'lodash';
import React, { FC } from 'react';
import { useSelector } from '../../../store';
import {
  GetTimeSeriesSumApiArg,
  TimeSeriesMetricId,
  useGetTimeSeriesSumQuery
} from '../../../store/pomeriumApi';
import { Card, CardContent, Stack } from '@mui/material';
import { Skeleton } from '@mui/lab';

interface Props {
  metricId: TimeSeriesMetricId;
  title: string;
}

const PeriodChange: FC<Props> = ({ metricId, title }) => {
  const { organizationId, clusterId } = useSelector((state) => state.current);
  const { routeId, reportPeriod } = useSelector((state) => state.metrics);
  const now = new Date(Math.floor(Date.now() / 60000) * 60000);
  const start = new Date(now.getTime() - reportPeriod);
  const prevStart = new Date(start.getTime() - reportPeriod);
  let params: GetTimeSeriesSumApiArg = {
    organizationId,
    clusterId,
    metricId,
    startTime: start.toISOString(),
    endTime: now.toISOString(),
    routeId: routeId
  };
  let prevParams: GetTimeSeriesSumApiArg = {
    organizationId,
    clusterId,
    metricId,
    startTime: prevStart.toISOString(),
    endTime: start.toISOString(),
    routeId: routeId
  };
  if (!routeId) {
    params = omit(params, ['routeId']);
    prevParams = omit(prevParams, ['routeId']);
  }

  const { data: period } = useGetTimeSeriesSumQuery(params, {
    skip: !organizationId || !clusterId || !reportPeriod
  });
  const { data: prevPeriod } = useGetTimeSeriesSumQuery(prevParams, {
    skip: !organizationId || !clusterId || !reportPeriod
  });

  const count = period?.reduce((count, row) => count + row.value, 0) || 0;

  const previousCount =
    prevPeriod?.reduce((count, row) => count + row.value, 0) || 0;

  const change =
    count && previousCount
      ? Math.round(((count - previousCount) / previousCount) * 100)
      : 0;
  const hasChange = isFinite(change) && change !== 0;

  const loading = !period || !prevPeriod;

  return (
    <Card
      sx={{
        width: '100%',
        m: 2
      }}
    >
      <CardContent>
        <Stack direction="row" justifyContent="center">
          <Typography variant={'h4'} color="text.primary" gutterBottom>
            {title}
          </Typography>
        </Stack>
        {loading && (
          <Skeleton width="100%" height="100%" sx={{ transform: 'unset' }} />
        )}
        {!loading && (
          <Stack direction="row" justifyContent="center">
            <Typography
              variant="h4"
              color={isFinite(count) ? 'textPrimary' : 'textSecondary'}
              align={hasChange ? 'right' : 'center'}
            >
              {isFinite(count) ? Math.round(count).toLocaleString() : 'No data'}
            </Typography>
            {hasChange && (
              <>
                <Typography
                  gutterBottom={false}
                  variant={'body2'}
                  sx={Object.assign(
                    {
                      display: 'inline-flex',
                      padding: 0.25,
                      fontSize: '.8rem',
                      fontWeight: 'bold'
                    },
                    {
                      color: change > 0 ? 'success.dark' : 'error.dark'
                    }
                  )}
                >
                  {change > 0 ? '↑' : '↓'}
                </Typography>
                <Typography
                  gutterBottom={false}
                  variant={'body2'}
                  sx={{
                    display: 'inline-flex',
                    padding: 0.25,
                    fontSize: '.8rem',
                    fontWeight: 'normal',
                    color: 'textSecondary'
                  }}
                >
                  {change > 0 ? '+' : '-'}
                  {Math.round(
                    Math.abs(count - previousCount)
                  ).toLocaleString()}{' '}
                  ({change > 0 ? '+' : ''}
                  {change}
                  %)
                </Typography>
              </>
            )}
          </Stack>
        )}
      </CardContent>
    </Card>
  );
};

export default PeriodChange;
