import {
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
  TypedUseSelectorHook
} from 'react-redux';
import type { ThunkAction } from 'redux-thunk';
import { configureStore, AnyAction } from '@reduxjs/toolkit';
import rootReducer from './root-reducer';
import { rtkQueryErrorToast } from './errorMiddleware';
import { pomeriumApi } from './pomeriumApi';

export const store = configureStore({
  reducer: rootReducer(),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([rtkQueryErrorToast, pomeriumApi.middleware]),
  devTools: true
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export type AppThunk = ThunkAction<any, RootState, unknown, AnyAction>;

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

export const useDispatch = () => useReduxDispatch<AppDispatch>();
