import { combineReducers } from '@reduxjs/toolkit';
import { reducer as notificationsReducer } from '../slices/notifications';
import { reducer as routesReducer } from '../slices/routes';
import { reducer as helpDocsReducer } from '../slices/help_docs';
import { reducer as onboardingReducer } from '../slices/onboarding';
import { reducer as currentReducer } from '../slices/current';
import { reducer as settingsReducer } from '../slices/settings';
import { reducer as policiesReducer } from '../slices/policies';
import { reducer as namespaceReducer } from '../slices/namespaces';
import { reducer as organizationsReducer } from '../slices/organizations';
import { reducer as pricingInfoReducer } from '../slices/pricing_info';
import { reducer as metricsReducer } from '../slices/metrics';
import { pomeriumApi } from './pomeriumApi';

const rootReducer = () =>
  combineReducers({
    notifications: notificationsReducer,
    routes: routesReducer,
    helpDocs: helpDocsReducer,
    api: pomeriumApi.reducer,
    onboarding: onboardingReducer,
    current: currentReducer,
    settings: settingsReducer,
    policies: policiesReducer,
    namespaces: namespaceReducer,
    organizations: organizationsReducer,
    pricingInfo: pricingInfoReducer,
    metrics: metricsReducer
  });
export default rootReducer;
