import MuiTextField from '@mui/material/TextField';
import { TextFieldProps } from '@mui/material/TextField';
import React, { ReactElement } from 'react';

const TextField = (props: Omit<TextFieldProps, 'variant'>): ReactElement => {
  return (
    <MuiTextField {...props} variant="outlined" size="small" color="primary" />
  );
};
export default TextField;
