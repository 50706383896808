import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface PricingInfoState {
  showPricingInfo: boolean;
  bannerMessage: string;
}

const initialState: PricingInfoState = {
  showPricingInfo: false,
  bannerMessage: ''
};

const slice = createSlice({
  name: 'pricingInfo',
  initialState,
  reducers: {
    closePricing(state: PricingInfoState): void {
      state.showPricingInfo = false;
      state.bannerMessage = '';
    },
    setBannerMessage(
      state: PricingInfoState,
      action: PayloadAction<string>
    ): void {
      state.bannerMessage = action.payload;
      state.showPricingInfo = true;
    }
  }
});

export const { reducer, actions } = slice;
export const { closePricing, setBannerMessage } = actions;
