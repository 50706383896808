import React, { FC, useEffect } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider
} from 'react-router-dom';
import useNotifier from '../hooks/use-notifier';
import { AuthGuard } from '../permissions/auth-guard';
import { useDispatch } from '../store';
import { setHelpDocs } from '../slices/help_docs';
import { NotLoggedInGuard } from '../permissions/not-logged-in';
import { NeedsOnboarding } from '../permissions/needs-onboarding';
import lazyWithRetry from '../components/LazyWithRetry';
import Usage from './usage';
import Traffic from './traffic';
import ApiDocs from '../components/ApiDocs';
import { Logout } from './firebase/logout';
import { useBooleanFlagValue } from '@openfeature/react-sdk';

const NotFoundView = lazyWithRetry(() => import('./errors/NotFoundView'));
const DashboardLayout = lazyWithRetry(() => import('../layouts/dashboard'));
const EnvoyRoutes = lazyWithRetry(() => import('../views/routes'));
const Policies = lazyWithRetry(() => import('../views/policies'));
const Certificates = lazyWithRetry(() => import('../views/certificates'));
const ClusterSettings = lazyWithRetry(() => import('../views/settings'));
const Login = lazyWithRetry(() => import('./firebase/login'));
const CreateAccount = lazyWithRetry(() => import('./firebase/createAccount'));
const VerifyEmail = lazyWithRetry(() => import('./firebase/verifyEmail'));
const Onboarding = lazyWithRetry(() => import('../views/onboarding'));
const Organizations = lazyWithRetry(() => import('../views/organizations'));
const Clusters = lazyWithRetry(() => import('../views/clusters'));
const Account = lazyWithRetry(() => import('../views/account'));
const Deployments = lazyWithRetry(() => import('../views/deployments'));
const ServiceAccounts = lazyWithRetry(() => import('../views/serviceAccounts'));
const Status = lazyWithRetry(() => import('../views/status'));
const ApiTokens = lazyWithRetry(() => import('../views/apiTokens'));
const Billing = lazyWithRetry(() => import('../views/billing'));
const FirebaseHandlers = lazyWithRetry(
  () => import('../views/firebase/handlers')
);

const Router: FC = () => {
  const dispatch = useDispatch();
  useNotifier();

  useEffect(() => {
    dispatch(setHelpDocs());
  }, [dispatch]);

  const metricsEnabled = useBooleanFlagValue('traffic-metrics', false);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="*" element={<NotFoundView />} />
        <Route path="/handlers" element={<FirebaseHandlers />} />
        <Route element={<NotLoggedInGuard />}>
          <Route path="/" element={<Navigate to={'/login'} />} />
          <Route path="/login" element={<Login />} />
          <Route path="/create-account" element={<CreateAccount />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
        </Route>
        <Route element={<AuthGuard />}>
          <Route path="/logout" element={<Logout />} />
          <Route path="/onboarding" element={<Onboarding />} />
          <Route element={<NeedsOnboarding />}>
            <Route path="/openapi" element={<ApiDocs />} />
            <Route path="/app/*" element={<DashboardLayout />}>
              <Route path="clusters" element={<Clusters />} />
              <Route path="account" element={<Account />} />
              <Route path="billing/*" element={<Billing />} />
              <Route path="organizations/*" element={<Organizations />} />
              <Route path="management">
                <Route path="policies/*" element={<Policies />} />
                <Route path="routes/*" element={<EnvoyRoutes />} />
                <Route path="certificates/*" element={<Certificates />} />
                <Route path="api-tokens/*" element={<ApiTokens />} />
                <Route
                  path="service-accounts/*"
                  element={<ServiceAccounts />}
                />
              </Route>
              <Route path="settings/*" element={<ClusterSettings />} />
              <Route path="reports">
                <Route path="status/*" element={<Status />} />
                <Route path="deployments/*" element={<Deployments />} />
                <Route path="usage/*" element={<Usage />} />
                {metricsEnabled && (
                  <Route path="traffic/*" element={<Traffic />} />
                )}
              </Route>
            </Route>
          </Route>
        </Route>
      </>
    )
  );

  return <RouterProvider router={router} />;
};

export default Router;
