import React, { FC, useEffect, useState } from 'react';
import { useSelector } from '../../store';
import { useCreateCheckoutSessionMutation } from '../../store/pomeriumApi';
import { useBooleanFlagValue } from '@openfeature/react-sdk';
import { buildCancelUrl } from './cancel';
import { useSearchParams } from 'react-router-dom';
import { buildSuccessUrl } from './success';
import { Backdrop, CircularProgress } from '@mui/material';

export function buildResubscribeUrl(returnUrl: string | null): string {
  return `${location.origin}/app/billing/resubscribe${returnUrl ? `?returnUrl=${encodeURIComponent(returnUrl)}` : ''}`;
}

const Resubscribe: FC = () => {
  const { organizationId } = useSelector((state) => state.current);
  const [createCheckoutSession] = useCreateCheckoutSessionMutation();
  const stripeBillingEnabled = useBooleanFlagValue('stripe-billing', false);
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!stripeBillingEnabled) {
      location.href =
        'mailto:sales@pomerium.com?subject=Interest in Upgrading to Business Level Pomerium Zero';
      setLoading(false);
      return;
    }

    (async () => {
      try {
        const result = await createCheckoutSession({
          organizationId,
          createCheckoutSessionRequest: {
            cancelUrl: buildCancelUrl(searchParams.get('returnUrl')),
            successUrl: buildSuccessUrl(searchParams.get('returnUrl'))
          }
        });
        if ('data' in result && result.data.url) {
          location.href = result.data.url;
        }
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <>
      <Backdrop open={loading}>
        <CircularProgress />
      </Backdrop>
    </>
  );
};

export default Resubscribe;
