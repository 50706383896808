import { createSlice, PayloadAction, Selector } from '@reduxjs/toolkit';
import objectHash from 'object-hash';
import { RootState } from '../store';
import { get, PropertyPath, set, unset } from 'lodash';
import { formError, formUnsetter, formUpdater } from '../types/form';
import { ClusterProperties, SettingsProperties } from '../store/pomeriumApi';
import { SettingsState } from '../types/settings';

export const emptySettings: SettingsProperties = {
  address: '',
  autoApplyChangesets: true,
  cookieExpire: '0s',
  cookieHttpOnly: false,
  cookieName: '',
  defaultUpstreamTimeout: '0s',
  dnsLookupFamily: 'V4_PREFERRED',
  logLevel: '',
  passIdentityHeaders: false,
  skipXffAppend: false,
  timeoutIdle: '0s',
  timeoutRead: '0s',
  timeoutWrite: '0s',
  tracingSampleRate: 0
};

export const emptyCluster: ClusterProperties = {
  name: '',
  manualOverrideIpAddress: ''
};

const initialState: SettingsState = {
  settingsHash: '',
  form: emptySettings,
  clusterHash: '',
  clusterForm: emptyCluster,
  formErrors: {}
};

const slice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setSettingsForm(
      state: SettingsState,
      action: PayloadAction<SettingsProperties>
    ): void {
      const hash = objectHash(action.payload);
      state.form = action.payload;
      state.settingsHash = hash;
    },
    setSettingsFormProperty(
      state: SettingsState,
      action: PayloadAction<formUpdater>
    ): void {
      if (
        typeof action.payload.value === 'undefined' ||
        action.payload.value === ''
      ) {
        unset(state.form || {}, action.payload.path);
      } else {
        set(state.form || {}, action.payload.path, action.payload.value);
      }
    },
    setClusterForm(
      state: SettingsState,
      action: PayloadAction<ClusterProperties>
    ): void {
      const hash = objectHash(action.payload);
      state.clusterForm = action.payload;
      state.clusterHash = hash;
    },
    setClusterFormProperty(
      state: SettingsState,
      action: PayloadAction<formUpdater>
    ): void {
      if (
        typeof action.payload.value === 'undefined' ||
        action.payload.value === ''
      ) {
        unset(state.clusterForm || {}, action.payload.path);
      } else {
        set(state.clusterForm || {}, action.payload.path, action.payload.value);
      }
    },
    setNumericSettingsFormProperty(
      state: SettingsState,
      action: PayloadAction<formUpdater>
    ): void {
      if (typeof action.payload.value === 'undefined') {
        unset(state.form || {}, action.payload.path);
      } else {
        set(
          state.form || {},
          action.payload.path,
          parseFloat(action.payload.value)
        );
      }
    },
    setPercentageSettingsFormProperty(
      state: SettingsState,
      action: PayloadAction<formUpdater>
    ): void {
      if (typeof action.payload.value === 'undefined') {
        unset(state.form || {}, action.payload.path);
      } else {
        const v = parseFloat(action.payload?.value?.trim()) / 100;
        if (!isNaN(v)) {
          set(state.form || {}, action.payload.path, v);
        }
      }
    },
    setSettingsFormError(
      state: SettingsState,
      action: PayloadAction<formError>
    ): void {
      if (action.payload.error) {
        set(state.formErrors, action.payload.path, action.payload.error);
      } else {
        unset(state.formErrors, action.payload.path);
      }
    },
    clearForm(state: SettingsState): void {
      state.settingsHash = '';
      state.clusterHash = '';
      state.formErrors = {};
      state.clusterForm = emptyCluster;
      state.form = emptySettings;
    },
    unsetSettingsFormProperties(
      state: SettingsState,
      action: PayloadAction<formUnsetter>
    ): void {
      for (const path of action.payload.paths) {
        unset(state.form || {}, path);
      }
    }
  }
});

export const { reducer, actions } = slice;
export const {
  setSettingsForm,
  setSettingsFormProperty,
  setSettingsFormError,
  setNumericSettingsFormProperty,
  setPercentageSettingsFormProperty,
  setClusterForm,
  setClusterFormProperty,
  clearForm,
  unsetSettingsFormProperties
} = actions;

export const getClusterFormProperty =
  (path: PropertyPath, defaultValue: any): Selector =>
  (store: RootState) => {
    return get(
      store.settings.clusterForm,
      path,
      defaultValue
    ) as typeof defaultValue;
  };

export const getSettingsFormProperty =
  (path: PropertyPath, defaultValue: any): Selector =>
  (store: RootState) => {
    return get(store.settings.form, path, defaultValue) as typeof defaultValue;
  };

export const getPercentageSettingsFormProperty =
  (path: PropertyPath, defaultValue: any): Selector =>
  (store: RootState) => {
    const val = get(
      store.settings.form,
      path,
      defaultValue
    ) as typeof defaultValue;
    return !val ? '' : (val * 100).toString();
  };
