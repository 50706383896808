import React, { FC, useContext, useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import {
  useListNamespacesQuery,
  useListOrganizationsQuery
} from '../store/pomeriumApi';
import { buildTree } from '../slices/namespaces';
import { indexOrganizations } from '../slices/organizations';
import { useDispatch, useSelector } from '../store';
import { UserContext } from '../contexts/UserContext';

export const NeedsOnboarding: FC = () => {
  const dispatch = useDispatch();
  const { organizationId } = useSelector((state) => state.current);
  const {
    data: organizations,
    isLoading,
    isFetching
  } = useListOrganizationsQuery();
  const { data: namespaces } = useListNamespacesQuery(organizationId, {
    skip: !organizationId
  });
  const { user } = useContext(UserContext);

  useEffect(() => {
    dispatch(indexOrganizations(organizations || []));
  }, [JSON.stringify(organizations)]);

  useEffect(() => {
    if (namespaces) dispatch(buildTree(namespaces));
  }, [JSON.stringify(namespaces), organizationId]);

  if (isLoading || isFetching) {
    return <></>;
  }

  if (user?.needsOnboarding) {
    return <Navigate to="/onboarding" replace />;
  } else {
    return <Outlet />;
  }
};
