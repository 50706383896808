import React, { useEffect } from 'react';
import { useDispatch } from '../../store';
import { logout } from '../../slices/current';

export const Logout = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(logout());
  }, []);

  return <></>;
};
