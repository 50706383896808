import { isRejectedWithValue } from '@reduxjs/toolkit';
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit';
import { pushNotification } from '../slices/notifications';
import { v4 as uuid } from 'uuid';
import { forEach, isArray, isString } from 'lodash';

//automatic toast messages on api errors
export const rtkQueryErrorToast: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (
      isRejectedWithValue(action) &&
      action.type === 'api/executeMutation/rejected'
    ) {
      toastError(api, action?.payload?.data || action?.payload);
    }
    return next(action);
  };

function toastError(api: MiddlewareAPI, data: any): void {
  if (isArray(data?.errors)) {
    forEach(data?.errors, (e) => toastError(api, e));
    return;
  }

  let msg = `${data?.error}`;
  if (isString(data?.source?.pointer)) {
    msg = `${data?.source?.pointer?.substr(1)}: ${msg}`;
  }

  api.dispatch(
    pushNotification({
      message: msg,
      options: {
        key: uuid(),
        variant: 'error'
      }
    })
  );
}
