import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useEffect, ReactElement, useContext } from 'react';
import type { HTMLProps, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';
import sendStandardEvent from '../hooks/use-firebase-event';
import ErrorBoundary from './ErrorBoundary';

interface PageProps extends HTMLProps<HTMLDivElement> {
  children?: ReactNode;
  title?: string;
}

const Page = ({ children, title }: PageProps): ReactElement => {
  const { user } = useContext(UserContext);
  const location = useLocation();
  const theme = useTheme();

  useEffect(() => {
    if (typeof title === 'string') {
      document.title = title;
    }
  }, [title]);

  useEffect(() => {
    sendStandardEvent('Page view', 'page', user, {
      page_path: location.pathname
    });
  }, [location]);

  return (
    <Box
      sx={{
        padding: 2,
        paddingRight: 3,
        minHeight: 'calc(100vh - 150px)',
        marginTop: theme.mixins.toolbar.height,
        alignItems: 'center'
      }}
    >
      <ErrorBoundary>{children}</ErrorBoundary>
    </Box>
  );
};

export default Page;
