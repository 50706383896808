import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  namespaceId,
  NamespaceState,
  NamespaceTreeNode
} from '../types/namespaces';
import { Namespace } from '../store/pomeriumApi';
import { AppThunk } from '../store';
import { get, set } from 'lodash';

const initialState: NamespaceState = {
  rootId: '',
  byId: {}
};

const slice = createSlice({
  name: 'namespaces',
  initialState,
  reducers: {
    setRootId(state: NamespaceState, action: PayloadAction<namespaceId>): void {
      state.rootId = action.payload;
    },
    setById(
      state: NamespaceState,
      action: PayloadAction<Record<namespaceId, NamespaceTreeNode>>
    ): void {
      state.byId = action.payload;
    }
  }
});

export const { reducer } = slice;

export const buildTree =
  (namespaces: Namespace[]): AppThunk =>
  async (dispatch): Promise<void> => {
    const byId = {};
    namespaces.forEach((namespace) => {
      if (namespace.type === 'root') {
        dispatch(slice.actions.setRootId(namespace.id));
      }
      const existingChildren = get(
        //edge case
        byId,
        namespace.id + '.children',
        [] as namespaceId[]
      );
      set(byId, namespace.id, { namespace, children: existingChildren });
      if (namespace.parentId) {
        const path = namespace.parentId + '.children';
        const siblings = get(byId, path, [] as namespaceId[]);
        set(byId, path, siblings.concat(namespace.id));
      }
    });
    dispatch(slice.actions.setById(byId));
  };
