import React, { FC, useEffect } from 'react';
import Page from '../../components/Page';
import { Container } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { useCompleteCheckoutSessionMutation } from '../../store/pomeriumApi';
import { useSelector } from '../../store';

export function buildSuccessUrl(returnUrl: string | null): string {
  return (
    `${location.origin}/app/billing/success` +
    `?checkoutSessionId={CHECKOUT_SESSION_ID}` + // this is a special template variable that gets filled in by stripe
    `${returnUrl ? `&returnUrl=${encodeURIComponent(returnUrl)}` : ''}`
  );
}

const Success: FC = () => {
  const [searchParams] = useSearchParams();
  const { organizationId } = useSelector((state) => state.current);
  const [completeCheckoutSession] = useCompleteCheckoutSessionMutation();

  useEffect(() => {
    const checkoutSessionId = searchParams.get('checkoutSessionId');
    if (checkoutSessionId) {
      completeCheckoutSession({
        organizationId,
        completeCheckoutSessionRequest: {
          checkoutSessionId: checkoutSessionId
        }
      });
    }

    const returnUrl = searchParams.get('returnUrl');
    if (returnUrl) {
      location.href = returnUrl;
    }
  }, [searchParams.get('checkoutSessionId'), searchParams.get('returnUrl')]);

  return (
    <Page title="Success">
      <Container maxWidth={false}>Checkout Completed</Container>
    </Page>
  );
};
export default Success;
