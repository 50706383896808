import React, { FC, ReactElement } from 'react';
import Page from '../../components/Page';
import Container from '@mui/material/Container';
import { useSelector } from '../../store';
import { Stack, useMediaQuery } from '@mui/material';
import Header from '../../components/Header';

import LoadingScreen from '../../components/LoadingScreen';
import { useListRoutesQuery } from '../../store/pomeriumApi';
import { AutocompleteRenderInputParams } from '@mui/material/Autocomplete/Autocomplete';
import TextField from '../../components/styled/TextField';
import Autocomplete from '../../components/styled/Autocomplete';
import { SelectorOption } from '../../components/Redux/BasicSelector';
import { useDispatch } from 'react-redux';
import { setReportPeriod, setRouteId } from '../../slices/metrics';
import { get } from 'lodash';
import PeriodChange from './components/PeriodChange';
import AuthorizedRequests from './components/AuthorizedRequests';
import { useTheme } from '@mui/material/styles';
import RequestDurationPercent from './components/RequestDurationPercent';
import RequestsPerSecond from './components/RequestsPerSecond';
import Bytes from './components/Bytes';
import Box from '@mui/material/Box';
import RequestDuration from './components/RequestDuration';

interface Range {
  label: string;
  id: number; //offset milliseconds
}

export const ThirtyDays = 1000 * 60 * 60 * 24 * 30;

const ranges: Range[] = [
  { label: 'last 30 days', id: ThirtyDays },
  { label: 'last 2 weeks', id: 1000 * 60 * 60 * 24 * 14 },
  { label: 'last 7 days', id: 1000 * 60 * 60 * 24 * 7 },
  { label: 'last 24 hrs', id: 1000 * 60 * 60 * 24 },
  { label: 'last 12 hrs', id: 1000 * 60 * 60 * 12 },
  { label: 'last 3 hrs', id: 1000 * 60 * 60 * 3 },
  { label: 'last hour', id: 1000 * 60 * 60 }
];

const Traffic: FC = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('lg'));

  const { clusterId, organizationId } = useSelector((state) => state.current);
  const { routeId, reportPeriod } = useSelector((state) => state.metrics);
  const direction = smallScreen ? 'column' : 'row';
  const { data: routes } = useListRoutesQuery(
    {
      organizationId,
      namespaceId: clusterId,
      includeDescendants: true
    },
    { skip: !organizationId || !clusterId }
  );

  const routeOptions =
    routes?.map((r) => ({ id: r.id, label: r.name }) as SelectorOption) || [];
  routeOptions.unshift({ id: '', label: 'All Routes' });

  if (!routes) {
    return <LoadingScreen />;
  }

  return (
    <Page title="Traffic">
      <Header breadcrumbs={true} title="Traffic" />
      <Container maxWidth={false}>
        <Stack direction={direction} justifyContent="space-between" gap={5}>
          <Autocomplete
            sx={{ m: 2 }}
            fullWidth
            size="small"
            value={ranges.find(
              (option) => (option as Range).id === reportPeriod
            )}
            getOptionLabel={(value) => (value as Range).label}
            options={ranges}
            renderInput={(
              params: AutocompleteRenderInputParams
            ): ReactElement => <TextField {...params} />}
            onChange={(_event, option: unknown): void => {
              dispatch(
                setReportPeriod(get(option, 'id', ThirtyDays) as number)
              );
            }}
          />
          <Autocomplete
            sx={{ m: 2 }}
            fullWidth
            size="small"
            value={routeOptions.find(
              (option) => (option as SelectorOption).id === routeId
            )}
            getOptionLabel={(value) => (value as SelectorOption).label}
            options={routeOptions}
            renderInput={(
              params: AutocompleteRenderInputParams
            ): ReactElement => <TextField {...params} />}
            onChange={(_event, option: unknown): void => {
              dispatch(setRouteId(get(option, 'id', '') as string));
            }}
          />
        </Stack>
        <Stack
          direction={direction}
          justifyContent="space-between"
          gap={5}
          sx={{ mt: 5 }}
        >
          <PeriodChange title="Total requests" metricId={'upstream_requests'} />
          <PeriodChange title="Authorized requests" metricId={'authz_ok'} />
        </Stack>
        <Stack
          direction={direction}
          gap={5}
          sx={{ mt: 5 }}
          justifyContent="space-between"
        >
          <AuthorizedRequests />
          <RequestDurationPercent />
        </Stack>
        <Stack direction={direction} justifyContent="center" sx={{ mt: 5 }}>
          <RequestsPerSecond />
        </Stack>
        <Stack
          direction={direction}
          justifyContent="space-between"
          gap={5}
          sx={{ mt: 5 }}
        >
          <Bytes direction="sent" />
          <Bytes direction="received" />
        </Stack>
        <Stack
          direction={direction}
          justifyContent="space-between"
          gap={5}
          sx={{ mt: 5 }}
        >
          <RequestDuration />
        </Stack>
        <Box m={3} />
      </Container>
    </Page>
  );
};

export default Traffic;
