import Typography from '@mui/material/Typography';
import { omit } from 'lodash';
import React, { FC } from 'react';
import { useSelector } from '../../../store';
import {
  GetTimeSeriesSumApiArg,
  useGetTimeSeriesSumQuery
} from '../../../store/pomeriumApi';
import { Card, CardContent } from '@mui/material';
import {
  AllSeriesType,
  ChartsLegend,
  ChartsTooltip,
  PiePlot,
  ResponsiveChartContainer
} from '@mui/x-charts';
import { useTheme } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import { Skeleton } from '@mui/lab';

const AuthorizedRequests: FC = () => {
  const theme = useTheme();
  const { organizationId, clusterId } = useSelector((state) => state.current);
  const { routeId, reportPeriod } = useSelector((state) => state.metrics);
  const now = new Date(Math.floor(Date.now() / 60000) * 60000);
  const start = new Date(now.getTime() - reportPeriod);
  let params: GetTimeSeriesSumApiArg = {
    organizationId,
    clusterId,
    startTime: start.toISOString(),
    endTime: now.toISOString(),
    routeId: routeId,
    metricId: 'authz_ok'
  };
  if (!routeId) {
    params = omit(params, ['routeId']);
  }

  const { data: ok } = useGetTimeSeriesSumQuery(params, {
    skip: !organizationId || !clusterId || !reportPeriod
  });
  const countOK = ok?.reduce((count, row) => count + row.value, 0) || 0;
  const { data: err } = useGetTimeSeriesSumQuery(
    { ...params, metricId: 'authz_err' },
    {
      skip: !organizationId || !clusterId || !reportPeriod
    }
  );
  const countERR = err?.reduce((count, row) => count + row.value, 0) || 0;
  const { data: denied } = useGetTimeSeriesSumQuery(
    { ...params, metricId: 'authz_denied' },
    {
      skip: !organizationId || !clusterId || !reportPeriod
    }
  );
  const countDENIED = denied?.reduce((count, row) => count + row.value, 0) || 0;
  const loading = !ok || !err || !denied;

  const data = [];
  if (countOK) {
    data.push({
      id: 0,
      value: Math.abs(countOK),
      label: 'Authorized',
      color: theme.palette.success.main
    });
  }
  if (countERR) {
    data.push({
      id: 1,
      value: Math.abs(countERR),
      label: 'Error',
      color: theme.palette.error.main
    });
  }

  if (countDENIED) {
    data.push({
      id: 2,
      value: Math.abs(countDENIED),
      label: 'Denied',
      color: theme.palette.warning.main
    });
  }

  return (
    <Card
      sx={{
        width: '100%',
        m: 2
      }}
    >
      <CardContent sx={{ height: '40vh', m: 2 }}>
        <Typography variant={'h4'} color="text.primary" gutterBottom>
          Authorized Requests
        </Typography>
        {loading && (
          <Skeleton width="100%" height="100%" sx={{ transform: 'unset' }} />
        )}
        {!data.length && !loading && (
          <Alert severity="info">
            No data to display. Data is updated hourly.
          </Alert>
        )}
        {!!(countOK || countERR || countDENIED) && (
          <ResponsiveChartContainer
            series={[
              {
                id: 'authorized-requests',
                type: 'pie',
                data: data
              } as AllSeriesType
            ]}
          >
            <ChartsLegend direction="row" />
            <ChartsTooltip trigger="item" />
            <PiePlot />
          </ResponsiveChartContainer>
        )}
      </CardContent>
    </Card>
  );
};

export default AuthorizedRequests;
