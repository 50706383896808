import { createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { AppThunk } from '../store';
import { SnackbarKey } from 'notistack';
import objectHash from 'object-hash';
import { forEach, isArray, isPlainObject, isString } from 'lodash';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

export interface NotistackOptions {
  variant: 'default' | 'error' | 'success' | 'warning' | 'info';
  key: SnackbarKey;
}

export interface NotistackNotification {
  message: string;
  options?: Partial<NotistackOptions>;
}

export interface NotificationState {
  notifications: NotistackNotification[];
}

const initialState: NotificationState = {
  notifications: []
};

const slice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    pushNotification(
      state: NotificationState,
      action: PayloadAction<NotistackNotification>
    ): void {
      state.notifications.push(action.payload);
    },
    removeNotification(
      state: NotificationState,
      action: PayloadAction<string | number | undefined>
    ): void {
      state.notifications = state.notifications.filter(
        (notification) => notification?.options?.key !== action.payload
      );
    }
  }
});

export const { reducer, actions } = slice;
export const { pushNotification } = actions;

export const pushAPIError =
  (error: FetchBaseQueryError | SerializedError): AppThunk =>
  async (dispatch): Promise<void> => {
    const data =
      'data' in error && isPlainObject(error.data)
        ? (error.data as Record<string, any>)
        : null;
    if (isArray(data?.errors)) {
      forEach(data.errors, (e) => dispatch(pushAPIError(e)));
      return;
    }

    let msg = `${data?.error}`;
    if (isString(data?.source?.pointer)) {
      msg = `${data?.source?.pointer?.substr(1)}: ${msg}`;
    }

    dispatch(
      slice.actions.pushNotification({
        message: msg,
        options: {
          key: objectHash(msg),
          variant: 'error'
        }
      })
    );
  };

export const pushError =
  (message: string): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(
      slice.actions.pushNotification({
        message: message,
        options: {
          key: objectHash(message),
          variant: 'error'
        }
      })
    );
  };

export const pushSuccess =
  (message: string): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(
      slice.actions.pushNotification({
        message: message,
        options: {
          key: objectHash(message),
          variant: 'success'
        }
      })
    );
  };

export const removeNotification =
  (key: string | number | undefined): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.removeNotification(key));
  };
