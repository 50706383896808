import SwaggerUI from 'swagger-ui-react';
import '../Swagger.css';
import 'swagger-ui-react/swagger-ui.css';
import React, { useEffect } from 'react';
import { firebaseAuth } from '../App';

export const complete = function (swaggerUi: any) {
  firebaseAuth.currentUser
    ?.getIdToken()
    .then((t) => swaggerUi.preauthorizeApiKey('bearerAuth', t));
};

const ApiDocs = () => {
  useEffect(() => {
    document?.getElementById('root')?.classList.add('swagger-force-light-mode');
    return () => {
      document
        ?.getElementById('root')
        ?.classList.remove('swagger-force-light-mode');
    };
  }, []);

  return (
    <SwaggerUI url="/openapi.yaml" onComplete={complete} persistAuthorization />
  );
};

export default ApiDocs;
