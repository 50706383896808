import { Auth, connectAuthEmulator, getAuth } from 'firebase/auth';
import { Analytics, getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

// config for the firebase emulator
const firebaseLocalEmulatorConfig = {
  emulatorHost: process.env.REACT_APP_FIREBASE_AUTH_EMULATOR_HOST,
  // see https://firebase.google.com/docs/emulator-suite/connect_auth#web-version-9
  // the `demo-` prefix ensures the mode is purely localhost emulator, with no binding to any cloud services
  projectId: 'demo-pomerium-zero-localhost',
  // apiKey can be set to anything but is required for the SDK to work
  apiKey: 'local-emulator-api-key'
};

const isLocalEmulator =
  process.env.NODE_ENV === 'development' &&
  firebaseLocalEmulatorConfig.emulatorHost !== undefined;

type FirebaseProperties = {
  auth: Auth;
  analytics: Analytics | null;
};

const fb = ((): FirebaseProperties => {
  if (isLocalEmulator) {
    const app = initializeApp(firebaseLocalEmulatorConfig);
    const auth = getAuth(app);
    connectAuthEmulator(
      auth,
      `http://${firebaseLocalEmulatorConfig.emulatorHost}`
    );
    return { auth, analytics: null };
  }
  const app = initializeApp(firebaseConfig);
  return {
    auth: getAuth(app),
    analytics: getAnalytics(app)
  };
})();

export const getFirebase = () => fb;
