import { CurrentState } from '../types/current';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { formUpdater } from '../types/form';
import { set } from 'lodash';
import { AppThunk } from '../store';
import { firebaseAuth } from '../App';
import { pomeriumApi } from '../store/pomeriumApi';

const initialState: CurrentState = {
  namespaceId: '',
  organizationId: '',
  clusterId: '',
  userId: ''
};

const slice = createSlice({
  name: 'current',
  initialState,
  reducers: {
    setCurrentProperty(
      state: CurrentState,
      action: PayloadAction<formUpdater>
    ): void {
      set(state || {}, action.payload.path, action.payload.value);
      if (action.payload.path === 'clusterId') {
        set(state || {}, 'namespaceId', action.payload.value);
      }
      if (action.payload.value) {
        localStorage.setItem(
          state.userId + '_' + (action.payload.path as string),
          action.payload.value
        );
      }
    }
  }
});

export const { reducer, actions } = slice;
export const { setCurrentProperty } = actions;

export const logout =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    await firebaseAuth.signOut();
    dispatch(pomeriumApi.util.resetApiState());
  };
