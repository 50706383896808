import MuiLink from '@mui/material/Link';
import { LinkProps as MuiLinkProps } from '@mui/material/Link/index';
import React, { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { To } from 'react-router';

export interface LinkProps extends MuiLinkProps {
  to: To;
  replace?: boolean;
  reloadDocument?: boolean;
}

const Link: FC<React.PropsWithChildren<LinkProps>> = (props: LinkProps) => (
  <MuiLink {...props} component={RouterLink as any} />
);

export default Link;
