import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {AppThunk} from "../store";
import helpInfo from "../json/help_docs.json";

export const HELP_DOCS_DEFAULT_BASE_URL = 'https://www.pomerium.com';

export interface HelpDoc {
  id: string;
  title: string;
  path?: string;
  description?: string;
  short_description?: string;
  services?: string[];
  type?: string;
}
export interface HelpDocsState {
  helpDocs: Record<docsKey, HelpDoc>;
}

export type docsKey = string;
export interface HelpDocsState {
  helpDocs: Record<docsKey, HelpDoc>;
}


const initialState: HelpDocsState = {
  helpDocs: {}
};

const slice = createSlice({
  name: 'helpDocs',
  initialState,
  reducers: {
    setHelpDocs(
      state: HelpDocsState,
      action: PayloadAction<Record<docsKey, HelpDoc>>
    ): void {
      state.helpDocs = action.payload;
    }
  }
});

export const { reducer } = slice;

export const setHelpDocs =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.setHelpDocs(helpInfo));
  };
