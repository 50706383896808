import Button, { ButtonProps } from '@mui/material/Button';
import React, { ReactElement, useState } from 'react';
import { CircularProgress } from '@mui/material';

const SaveButton = (props: ButtonProps): ReactElement => {
  const [preventDoubleClick, setPreventDoubleClick] = useState(false);
  const preventOnclick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setPreventDoubleClick(true);
    if (typeof props.onClick === 'function') {
      props.onClick(e);
    }
    setTimeout(() => {
      setPreventDoubleClick(false);
    }, 1000);
  };

  return (
    <Button
      {...props}
      startIcon={preventDoubleClick && <CircularProgress size={16} />}
      disabled={props.disabled || preventDoubleClick}
      onClick={preventOnclick}
    />
  );
};

export default SaveButton;
