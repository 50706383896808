import type { PaletteOptions } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { error, info, neutral, purple, secondary, success, warning } from '../colors';

export const createPalette = (): PaletteOptions => {
  return {
    action: {
      active: neutral[400],
      disabled: alpha(neutral[400], 0.38),
      disabledBackground: alpha(neutral[400], 0.12),
      focus: alpha(neutral[400], 0.16),
      hover: alpha(neutral[400], 0.04),
      selected: alpha(neutral[400], 0.12)
    },
    background: {
      default: neutral[900],
      paper: neutral[800]
    },
    divider: neutral[700],
    error,
    info,
    mode: 'dark',
    neutral,
    primary: {
      light: '#E6DAFC',
      main: '#CAB0F8',
      dark: '#9560F1',
      contrastText: '#000000'
    },
    secondary,
    success,
    text: {
      primary: '#fff',
      secondary: '#97A1BA',
      disabled: alpha('#fff', 0.38)
    },
    warning
  };
};
