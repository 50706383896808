import { OnboardingState } from '../types/onboarding';
import { createSlice, PayloadAction, Selector } from '@reduxjs/toolkit';
import { formUpdater } from '../types/form';
import { get, PropertyPath, set } from 'lodash';
import { RootState } from '../store';

const initialState: OnboardingState = {
  root_namespace_name: '',
  organization_name: ''
};

const slice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    setOnboardingProperty(
      state: OnboardingState,
      action: PayloadAction<formUpdater>
    ): void {
      set(state || {}, action.payload.path, action.payload.value);
    }
  }
});

export const { reducer, actions } = slice;
export const { setOnboardingProperty } = actions;

export const getOnboardingProperty =
  (path: PropertyPath): Selector =>
  (store: RootState) => {
    return get(store.onboarding, path, '') as string;
  };
