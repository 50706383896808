import Breadcrumbs from '@mui/material/Breadcrumbs';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { isArray } from 'lodash';
import React, { FC, ReactElement, useCallback } from 'react';
import Link from './styled/Link';
import { useSelector } from '../store';
import { NamespaceTreeNode } from '../types/namespaces';
import { Organization, useListOrganizationsQuery } from '../store/pomeriumApi';
import {
  Dialog,
  DialogContent,
  IconButton,
  Stack,
  SvgIcon
} from '@mui/material';
import { BookOpenIcon, VideoCameraIcon } from '@heroicons/react/24/solid';
import { useDialog } from '../hooks/use-dialog';
import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import { WistiaPlayer } from '@wistia/wistia-player/react';

type BreadcrumbLink = {
  to?: string;
  title: string;
};
interface Props {
  title: string;
  subtitle?: string;
  actions?: ReactElement;
  breadcrumbs?: boolean | BreadcrumbLink[];
  reducedPadding?: boolean;
  videoId?: string;
  helpUrl?: string;
}

const Header: FC<React.PropsWithChildren<Props>> = ({
  title,
  subtitle,
  actions,
  breadcrumbs,
  reducedPadding,
  helpUrl,
  videoId
}) => {
  const { byId } = useSelector((state) => state.namespaces);
  const { namespaceId, clusterId, organizationId } = useSelector(
    (state) => state.current
  );
  const { data: organizations } = useListOrganizationsQuery();
  const organization = organizations?.find(
    (organization) => organization.id === organizationId
  ) as Organization;
  const videoDialog = useDialog();

  const generateAncestorArray = useCallback(
    (
      namespaceId: string,
      clusterId: string,
      byId: Record<string, NamespaceTreeNode>
    ): NamespaceTreeNode[] => {
      let node = byId[namespaceId];
      const ancestors = [node];
      while (
        node?.namespace?.parentId &&
        node?.namespace?.parentId !== clusterId
      ) {
        node = byId[node?.namespace?.parentId || -1];
        if (node) {
          ancestors.unshift(node);
        }
      }
      if (namespaceId !== clusterId) {
        ancestors.unshift(byId[clusterId]);
      }

      return ancestors;
    },
    [namespaceId, clusterId, JSON.stringify(byId)]
  );

  return (
    <Container
      sx={{
        paddingBottom: reducedPadding ? 1.5 : 6
      }}
      maxWidth={false}
      disableGutters
    >
      <Dialog
        open={videoDialog.open}
        onClose={videoDialog.handleClose}
        scroll="paper"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
          {title} in Pomerium Zero
          <IconButton
            color="primary"
            onClick={videoDialog.handleClose}
            size="small"
            sx={{ ml: 'auto' }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <WistiaPlayer mediaId={videoId || ''} />
        </DialogContent>
      </Dialog>
      <Grid container spacing={3} justifyContent="space-between">
        <Grid item>
          <Stack direction="row" gap={1}>
            <Typography variant="h3" color="textPrimary">
              {title}
            </Typography>
            {helpUrl && (
              <IconButton onClick={() => window.open(helpUrl, '_blank')}>
                <SvgIcon color="primary" fontSize="small">
                  <BookOpenIcon />
                </SvgIcon>
              </IconButton>
            )}
            {videoId && (
              <IconButton
                color="primary"
                onClick={() => videoDialog.handleOpen()}
              >
                <SvgIcon color="primary" fontSize="small">
                  <VideoCameraIcon />
                </SvgIcon>
              </IconButton>
            )}
          </Stack>
          {subtitle ? (
            <Typography variant="subtitle2" color="textPrimary">
              {subtitle}
            </Typography>
          ) : (
            <></>
          )}
          {breadcrumbs === true ? (
            <Breadcrumbs aria-label="breadcrumb" maxItems={3}>
              {generateAncestorArray(namespaceId, clusterId, byId).map((n) => {
                let to = '/app/clusters';
                if (!n?.namespace?.parentId) {
                  to = '/app/organizations';
                  return (
                    <Link
                      key={'orgLink' + organizationId || ''}
                      underline="hover"
                      color="inherit"
                      to={to}
                    >
                      {organization?.name || ''}
                    </Link>
                  );
                }
                return (
                  <Link
                    key={'namespaceLink' + n?.namespace?.id || ''}
                    underline="hover"
                    color="inherit"
                    to={to}
                  >
                    {n?.namespace?.name || ''}
                  </Link>
                );
              })}
            </Breadcrumbs>
          ) : isArray(breadcrumbs) ? (
            <Breadcrumbs aria-label="breadcrumb" maxItems={3}>
              {breadcrumbs.map(({ to, title }) =>
                to ? (
                  <Link key={title} to={to} underline="hover" color="inherit">
                    {title}
                  </Link>
                ) : (
                  <Typography key={title}>{title}</Typography>
                )
              )}
            </Breadcrumbs>
          ) : (
            <></>
          )}
        </Grid>
        {actions ? <Grid item>{actions}</Grid> : <></>}
      </Grid>
    </Container>
  );
};
export default Header;
