import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from '../store';
import {
  Button,
  Card,
  Dialog,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography
} from '@mui/material';
import { closePricing } from '../slices/pricing_info';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import { CheckCircle, Close } from '@mui/icons-material';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { buildUpgradeUrl } from '../views/billing/upgrade';
import { get } from 'lodash';
import { buildResubscribeUrl } from '../views/billing/resubscribe';

const PricingInfo: FC = () => {
  const dispatch = useDispatch();
  const { showPricingInfo, bannerMessage } = useSelector(
    (state) => state.pricingInfo
  );
  const [showBanner, setShowBanner] = useState(true);
  const onClose = () => {
    dispatch(closePricing());
  };
  const { byId: orgById } = useSelector((state) => state.organizations);
  const { organizationId } = useSelector((state) => state.current);
  const organizationType = get(
    orgById,
    organizationId + '.organizationType',
    'personal'
  ) as string;
  const readOnly = get(orgById, organizationId + '.readOnly', false) as boolean;

  useEffect(() => {
    setShowBanner(showPricingInfo);
  }, [showPricingInfo]);

  const handleResubscribe = (_evt: React.MouseEvent): void => {
    location.href = buildResubscribeUrl(location.href);
  };

  const handleUpgrade = (_evt: React.MouseEvent): void => {
    location.href = buildUpgradeUrl(location.href);
  };

  return (
    <Dialog
      open={showPricingInfo}
      onClose={onClose}
      scroll="paper"
      maxWidth="lg"
      fullWidth
    >
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{ position: 'absolute', right: 8, top: 8 }}
      >
        <Close />
      </IconButton>
      <Stack gap={1} alignItems="center" sx={{ mt: 2 }}>
        {showBanner && bannerMessage && (
          <Alert severity="info">{bannerMessage}</Alert>
        )}
        {(!showBanner || !bannerMessage) && <Box m={2} />}
        <Typography variant="h3">Move fast and stay secure</Typography>
        <Typography variant="subtitle1">
          Ship faster and more securely with Pomerium. Upgrade for advanced
          features.
        </Typography>
        <Card sx={{ m: 2, p: 0 }}>
          <Grid container justifyContent="space-around">
            {organizationType === 'personal' && (
              <Grid item xs={4} sx={{ m: 0, p: '5px' }}>
                <Stack gap={1} sx={{ height: '125px', px: 3 }}>
                  <Typography variant="h4">Personal</Typography>
                  <Typography variant="subtitle1" sx={{ mb: 1 }}>
                    For individuals or smaller teams
                  </Typography>
                </Stack>
                <Stack gap={1} sx={{ p: 3 }}>
                  <Typography variant="h4" sx={{ mb: 3 }}>
                    Free
                  </Typography>
                  <Typography variant="body1" fontSize={20} sx={{ mb: 1 }}>
                    Your current plan
                  </Typography>
                  <List disablePadding>
                    <ListItem disablePadding>
                      <ListItemIcon>
                        <CheckCircle />
                      </ListItemIcon>
                      <ListItemText primary="10 routes, 5 policies" />
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemIcon>
                        <CheckCircle />
                      </ListItemIcon>
                      <ListItemText primary="1 cluster, 1 service account" />
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemIcon>
                        <CheckCircle />
                      </ListItemIcon>
                      <ListItemText primary="1 custom domain included" />
                    </ListItem>
                  </List>
                </Stack>
              </Grid>
            )}
            <Grid
              item
              xs={4}
              sx={{
                m: 0,
                p: 0,
                border: '5px #6F43E7 solid',
                borderRadius: 1
              }}
            >
              <Stack gap={1} sx={{ height: '125px', px: 3 }}>
                <Typography variant="h4">Business</Typography>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  For teams that prefer a managed solution while keeping data
                  governance
                </Typography>
              </Stack>
              <Stack gap={1} sx={{ p: 3 }}>
                <Typography variant="h4" sx={{ mb: 3 }}>
                  $7.00{' '}
                  <Typography variant="subtitle2" component="span">
                    per user/month
                  </Typography>
                </Typography>
                {organizationType === 'personal' ? (
                  <Button
                    variant="contained"
                    sx={{ width: '70%', mb: 1 }}
                    onClick={handleUpgrade}
                  >
                    Upgrade
                  </Button>
                ) : readOnly ? (
                  <Button
                    variant="contained"
                    sx={{ width: '70%', mb: 1 }}
                    onClick={handleResubscribe}
                  >
                    Renew
                  </Button>
                ) : (
                  'Your Current Plan'
                )}
                <List disablePadding>
                  <ListItem disablePadding>
                    <ListItemIcon>
                      <CheckCircle color="primary" />
                    </ListItemIcon>
                    <ListItemText primary="100 routes, 100 policies" />
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemIcon>
                      <CheckCircle color="primary" />
                    </ListItemIcon>
                    <ListItemText primary="5 clusters, 20 service accounts" />
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemIcon>
                      <CheckCircle color="primary" />
                    </ListItemIcon>
                    <ListItemText primary="5 custom domains" />
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemIcon>
                      <CheckCircle color="primary" />
                    </ListItemIcon>
                    <ListItemText primary="And more" />
                  </ListItem>
                </List>
              </Stack>
            </Grid>
            <Grid item xs={4} sx={{ m: 0, p: '5px' }}>
              <Stack gap={1} sx={{ height: '125px', px: 3 }}>
                <Typography variant="h4">Enterprise</Typography>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  For larger teams that need advanced access controls and
                  governance capabilities at scale
                </Typography>
              </Stack>
              <Stack gap={1} sx={{ p: 3 }}>
                <Typography variant="h4" sx={{ mb: 3 }}>
                  Chat with us
                </Typography>
                <Button
                  variant="contained"
                  color="info"
                  sx={{ width: '70%', mb: 1 }}
                  href="https://www.pomerium.com/contact"
                >
                  Contact sales
                </Button>
                <Typography variant="subtitle1">
                  Everything in Business, plus:
                </Typography>
                <List disablePadding>
                  <ListItem disablePadding>
                    <ListItemIcon>
                      <CheckCircle />
                    </ListItemIcon>
                    <ListItemText primary="White labeling" />
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemIcon>
                      <CheckCircle />
                    </ListItemIcon>
                    <ListItemText primary="Advanced policy (Rego) support" />
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemIcon>
                      <CheckCircle />
                    </ListItemIcon>
                    <ListItemText primary="And more" />
                  </ListItem>
                </List>
              </Stack>
            </Grid>
          </Grid>
        </Card>
        <Box sx={{ mb: 3 }}>
          <Link
            color="primary"
            href="https://www.pomerium.com/pricing"
            target="_blank"
            variant="body2"
          >
            See all features
          </Link>
        </Box>
      </Stack>
    </Dialog>
  );
};
export default PricingInfo;
