import { Provider } from '@openfeature/web-sdk';
import { FlagdWebProvider } from '@openfeature/flagd-web-provider';
import { LaunchDarklyClientProvider } from '@openfeature/launchdarkly-client-provider';

export function getOpenFeatureProvider(): Provider {
  if (process.env.REACT_APP_LAUNCHDARKLY_CLIENT_ID) {
    return new LaunchDarklyClientProvider(
      process.env.REACT_APP_LAUNCHDARKLY_CLIENT_ID,
      {}
    );
  }
  if (process.env.REACT_APP_FLAGD_HOST) {
    return new FlagdWebProvider({
      host: process.env.REACT_APP_FLAGD_HOST,
      port: process.env.REACT_APP_FLAGD_PORT
        ? parseInt(process.env.REACT_APP_FLAGD_PORT)
        : undefined,
      tls: process.env.REACT_APP_FLAGD_TLS
        ? process.env.REACT_APP_FLAGD_PORT === 'true'
        : undefined
    });
  }

  throw 'either REACT_APP_LAUNCHDARKLY_CLIENT_ID or REACT_APP_FLAGD_HOST is required';
}
