import {SnackbarKey, useSnackbar} from 'notistack';
import { useEffect, useState } from 'react';

import { removeNotification } from '../slices/notifications';
import { useDispatch, useSelector } from '../store';

const useNotifier = (): void => {
  const dispatch = useDispatch();
  const { notifications } = useSelector((state) => state.notifications);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [alreadyDisplayed, setAlreadyDisplayed] = useState<SnackbarKey[]>([]);

  const addToDisplayed = (id: SnackbarKey) => {
    setAlreadyDisplayed((prevState) => [...prevState, id]);
  };

  const removeFromDisplayed = (id: SnackbarKey) => {
    setAlreadyDisplayed((prevState) => prevState.filter((key) => id !== key));
  };

  useEffect(() => {
    notifications.forEach(({ message, options = {} }) => {
      if (alreadyDisplayed.includes(options?.key || '')) return;
      enqueueSnackbar(message, {
        ...options,
        autoHideDuration: 5000,
        onClose: (event, reason, key) => {
          dispatch(removeNotification(key));
          removeFromDisplayed(key as SnackbarKey);
        },
        onExited: (event, key) => {
          dispatch(removeNotification(key));
          removeFromDisplayed(key);
        }
      });

      addToDisplayed(options?.key as SnackbarKey);
    });
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);
};

export default useNotifier;
