import React, { Suspense } from 'react';
import './App.css';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import { xgrid_key } from './licenses/xgrid_key';
import { createEmotionCache } from './utils/create-emotion-cache';
import { CacheProvider } from '@emotion/react';
import { ThemeProvider } from '@mui/material/styles';
import { Provider } from 'react-redux';
import { store } from './store';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  SettingsConsumer,
  SettingsProvider
} from './contexts/settings-context';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { CssBaseline } from '@mui/material';
import { createTheme } from './theme';
import { SnackbarProvider } from 'notistack';
import SnackbarCloseButton from './components/SnackbarCloseButton';
import Router from './views/router';
import { getFirebase } from './configs/firebase';
import LoadingScreen from './components/LoadingScreen';
import { UserProvider } from './contexts/UserContext';
import { setSentryUser } from './configs/sentry';
import PricingInfo from './components/PricingInfo';
import '@fontsource-variable/inter';
import '@fontsource-variable/space-grotesk';
import { OpenFeature, OpenFeatureProvider } from '@openfeature/react-sdk';
import { getOpenFeatureProvider } from './utils/features';

declare global {
  interface Window {
    syft: {
      signup: (email: string, fields: Record<string, string>) => void;
      identify: (email: string, fields: Record<string, string>) => void;
    };
  }
}

//X-Grid License Key Installation for Material-Ui
LicenseInfo.setLicenseKey(xgrid_key);

const emotionCache = createEmotionCache();

export const { auth: firebaseAuth, analytics: firebaseAnalytics } =
  getFirebase();

firebaseAuth.onAuthStateChanged((u) => {
  setSentryUser(
    u
      ? {
          id: u.uid,
          email: u.email || undefined
        }
      : null
  );
});

OpenFeature.setProvider(getOpenFeatureProvider());

function App() {
  return (
    <CacheProvider value={emotionCache}>
      <OpenFeatureProvider>
        <Provider store={store}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <UserProvider>
              <SettingsProvider>
                <SettingsConsumer>
                  {(settings) => {
                    // Prevent theme flicker when restoring custom settings from browser storage
                    if (!settings.isInitialized) {
                      // return null;
                    }

                    const theme = createTheme({
                      direction: 'ltr',
                      paletteMode: settings.paletteMode
                    });

                    return (
                      <ThemeProvider theme={theme}>
                        <CssBaseline />
                        <SnackbarProvider
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                          }}
                          maxSnack={3}
                          action={(snackbarKey) => (
                            <SnackbarCloseButton snackbarKey={snackbarKey} />
                          )}
                        >
                          <Suspense fallback={<LoadingScreen />}>
                            <PricingInfo />
                            <Router />
                          </Suspense>
                        </SnackbarProvider>
                      </ThemeProvider>
                    );
                  }}
                </SettingsConsumer>
              </SettingsProvider>
            </UserProvider>
          </LocalizationProvider>
        </Provider>
      </OpenFeatureProvider>
    </CacheProvider>
  );
}

export default App;
