import Typography from '@mui/material/Typography';
import { omit } from 'lodash';
import React, { FC } from 'react';
import { useSelector } from '../../../store';
import {
  GetTimeSeriesApiArg,
  useGetTimeSeriesQuery
} from '../../../store/pomeriumApi';
import { Card, CardContent } from '@mui/material';
import {
  AllSeriesType,
  AxisConfig,
  ChartsGrid,
  ChartsTooltip,
  ChartsXAxis,
  ChartsXAxisProps,
  ChartsYAxis,
  LinePlot,
  ResponsiveChartContainer
} from '@mui/x-charts';
import Alert from '@mui/material/Alert';
import { MakeOptional } from '@mui/x-charts/internals';
import { ScaleName } from '@mui/x-charts/models/axis';
import { Skeleton } from '@mui/lab';

interface BytesProps {
  direction: 'sent' | 'received';
}

const Bytes: FC<BytesProps> = ({ direction }) => {
  const { organizationId, clusterId } = useSelector((state) => state.current);
  const { routeId, reportPeriod } = useSelector((state) => state.metrics);
  const now = new Date(Math.floor(Date.now() / 60000) * 60000);
  const start = new Date(now.getTime() - reportPeriod);
  let params: GetTimeSeriesApiArg = {
    organizationId,
    clusterId,
    startTime: start.toISOString(),
    endTime: now.toISOString(),
    routeId: routeId,
    metricId: direction === 'sent' ? 'upstream_tx_bytes' : 'upstream_rx_bytes'
  };
  if (!routeId) {
    params = omit(params, ['routeId']);
  }

  const { data: bytes } = useGetTimeSeriesQuery(params, {
    skip: !organizationId || !clusterId || !reportPeriod
  });

  const transformed = bytes?.map((ts) => {
    return {
      series: {
        id: 'bytes-' + direction,
        label: 'Bytes ' + direction,
        type: 'line',
        data: ts.points.map((p) => p.value)
      } as AllSeriesType<'line'>,
      xAxis: {
        id: 'bytes-x-' + direction,
        data: ts.points.map((p) => new Date(p.timestamp)),
        scaleType: 'utc',
        min: start,
        max: now
      } as AxisConfig<'utc', any, ChartsXAxisProps>
    };
  });

  return (
    <Card
      key={direction + '-bytes-chart'}
      sx={{
        width: '100%',
        m: 2
      }}
    >
      <CardContent sx={{ height: '40vh', m: 2 }}>
        {' '}
        <Typography variant={'h4'} color="text.primary" gutterBottom>
          {'Bytes ' + direction}
        </Typography>
        {!bytes && (
          <Skeleton width="100%" height="100%" sx={{ transform: 'unset' }} />
        )}
        {bytes && !bytes?.length && (
          <Alert severity="info">
            No data to display. Data is updated hourly.
          </Alert>
        )}
        {transformed && !!bytes?.length && (
          <ResponsiveChartContainer
            xAxis={
              transformed.map((d) => d.xAxis) as MakeOptional<
                AxisConfig<ScaleName, any, ChartsXAxisProps>,
                'id'
              >[]
            }
            series={transformed.map((d) => d.series)}
          >
            <LinePlot />
            <ChartsXAxis axisId={'bytes-x-' + direction} />
            <ChartsYAxis />
            <ChartsGrid horizontal />
            <ChartsTooltip trigger="axis" />
          </ResponsiveChartContainer>
        )}
      </CardContent>
    </Card>
  );
};

export default Bytes;
